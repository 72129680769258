<script>
import MainBackground from '@/assets/images/004.jpg'
import ImageBackgroundVue from '@/components/ui/ImageBackground.vue'

import SearchBarVue from '@/components/ui/SearchBar.vue'
import LanguageConverter from '@/util/LanguageConverter'
export default {
  name: 'researchField',
  components: { SearchBarVue, ImageBackgroundVue },
  data() {
    return {
      endpoint: this.$route.matched[0].path,
      searchConditions: [],
      src: MainBackground
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.searchConditions = [
      { value: 'all', text: '전체' },
      { value: 'labName', text: LanguageConverter({ en: 'Lab Name', kr: '연구실이름' }) },
      { value: 'departmentName', text: LanguageConverter({ en: 'Department', kr: '학과/부서' }) },
      { value: 'leadResearcherName', text: LanguageConverter({ en: 'Lead Researcher', kr: '대표연구자' }) },
      { value: 'keyword', text: LanguageConverter({ en: 'Keyword', kr: '키워드' }) }
    ]
  },
  unmounted() {},
  methods: {
    LanguageConverter,
    searchAction(input, condition) {
      this.$router.push(`/researchField/result?q=${encodeURIComponent(`${condition}=${input}`)}`)
    }
  },
  watch: {}
}
</script>

<template>
  <article class="wf__research-info">
    <ImageBackgroundVue :src="src">
      <div>
        <h1 class="wf__default-title">{{ LanguageConverter({ en: 'Research Field Search', kr: '연구분야 검색' }) }}</h1>
        <p class="wf__research-info-description">
          {{
            LanguageConverter({
              en: 'Researchers belonging to the contracting institution (transfer faculty, transfer researcher) can be inquired by researcher or department.',
              kr: '연구실, 기관별, 연구분야별로 검색이 가능합니다.'
            })
          }}
        </p>
      </div>
    </ImageBackgroundVue>
  </article>
  <section class="wf__research">
    <article class="wf__research-search">
      <SearchBarVue
        v-if="searchConditions.length !== 0"
        class="wf__research-search-bar"
        :searchConditions="searchConditions"
        @search="searchAction"
      />

      <div class="wf__research-search-tabs">
        <router-link class="wf__research-search-tab-item" :to="`${endpoint}/lab`"
          >{{ LanguageConverter({ en: 'Labs', kr: '연구실' }) }}
        </router-link>
        <router-link class="wf__research-search-tab-item" :to="`${endpoint}/major`">{{
          LanguageConverter({ en: 'Departmens', kr: '기관별' })
        }}</router-link>
        <router-link class="wf__research-search-tab-item" :to="`${endpoint}/field`">{{
          LanguageConverter({ en: 'Fields', kr: '연구분야' })
        }}</router-link>
      </div>

      <div class="divider"></div>
    </article>

    <article class="wf__research-results">
      <router-view></router-view>
    </article>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__research {
  min-height: 600px;
}

.wf__research-info {
  margin-bottom: 2.5em;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.wf__research-info-title {
  font-weight: bold;
  font-size: var(--wf-text-36);
}
.wf__research-info-description {
  font-size: var(--wf-text-16);
  margin-top: 0.75em;
  max-width: 36em;
  padding: 0 1em;
}

.wf__research-search {
  margin-bottom: 1.5em;
}
.wf__research-search-bar {
  margin-top: 3em;
  margin-bottom: 4em;
}
.wf__research-search-tabs {
  display: flex;
  justify-content: center;
  gap: 2em;
  font-size: var(--wf-text-20);
  font-weight: bold;
  padding-bottom: 0.5em;
}
.wf__research-search-tab-item {
  position: relative;
  padding: 0 0.5em;
}
.wf__research-search-tab-item.router-link-exact-active {
  color: var(--wf-primary-color);
}
.wf__research-search-tab-item.router-link-exact-active::after {
  position: absolute;
  bottom: -0.65em;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--wf-primary-color);
}
.divider {
  width: 100%;
  height: 3px;
  background-color: var(--wf-gray-color);
}
</style>
